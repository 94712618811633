<template>
    <div>
        <v-row :class="!$vuetify.breakpoint.sm && 'flex-wrap-reverse'">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-container fluid class="ma-0 pa-0 q_leads_1" id="lead_datatable">
                        <QDataTableHeader color="q_leads_1" ratio="5/7">
                            <template #left>
                                <div id="q-pending-policy-step-3">
                                    <p class="q-display-3 d-flex align-center white--text">
                                        <span v-if="title">{{ title }}</span>
                                        <v-btn dark icon @click="refreshData">
                                            <v-icon>fas fa-redo-alt</v-icon>
                                        </v-btn>
                                    </p>
                                </div>
                            </template>
                        </QDataTableHeader>
                        <v-data-table
                            :mobile-breakpoint="2"
                            :headers="allHeaders"
                            :items="all_data"
                            item-key="id"
                            class="elevation-0"
                            :footer-props="{itemsPerPageOptions:[10,25,50,100]}"
                            :loading="data_loading"
                            :search="search"
                        >
                            <template #item.State="{ item }">
                               {{ item.State.join() }}
                            </template>
                            <template #item.Counties="{ item }">
                                {{ item.Counties.join() }}
                            </template>
                            <template #item.Bid="{ item }">
                                <v-btn color="primary" @click.stop="processBid(item)" :disabled="item.BidPlaced">
                                    Bid on Order
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog eager v-model="showBidForm">
            <v-row>
                <v-col cols="12" class="fill-height d-flex flex-column justify-center align-center">
                    <v-card class="py-8 px-5">
                        <fml-question-set
                            ref="bid_form"
                            :hide-title="false"
                            slug="existing-lead-order-bid-form"
                            question-set-index="0"
                            v-model="response_answers"
                            :redirect-on-save="false"
                            :auto-save="false"
                            :last-set="true"
                            @submitted="updateBid"
                        >
                        </fml-question-set>
                    </v-card>
                </v-col>
            </v-row>
        </v-dialog>
    </div>
</template>
<script>
import QDataTableMixin from "@/components/datatables/QDataTableMixin.js"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDataTableHeader from '@/components/datatables/base/QDataTableHeader.vue'
import { debounce } from 'vue-debounce'
import FmlQuestionSet from '@/FML/views/FmlQuestionSet.vue';
export default {
    name: "QExistingLeadOrderAuctionDataTable",
    mixins: [QDataTableMixin],
    props: ['title'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            data_loading: false,
            response_data: null,
            search: '',
            table_headers: [
                {
                    text: 'Lead Order #',
                    value: 'LeadOrderNumber',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                {
                    text: 'State',
                    value: 'State',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                {
                    text: 'Qty',
                    value: 'Quantity',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                {
                    text: 'Primary Counties',
                    value: 'Counties',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
            ],
            auction_hash: null,
            showBidForm: false,
            question_set_data: null,
            response_answers: null,
        }
    },
    mounted: function() {
        this.refreshData()
    },
    computed: {
        filter_keys: function() {
            // these should match up with the keys for the model on the server side - availableFilters
        },
        all_data: function() {
            //instead of using a state/model on the frontend the data is all contained in this module.
            //this computed propery is required by the QDataTableMixin
            return this.response_data ?? []
        },

        allHeaders() {
            return this.table_headers
        },

        //server_available_filters will be set by our first data pull where the server responds with all data for the filter drop downs.
        //QDataTableMixin requires this computed property.
        'available_filters': function() {
            return this.server_available_filters
        },

        is_loading: function() {
            return this.data_loading
        }
    },
    methods: {
        //get called by the QDataTableMixin whenever we need to refresh the data from the server.
        loadData: debounce(function(opt) {
            this.data_loading = true
            let g = this
            // pull data
            QuilityAPI.getExistingLeadOrderAuctionData(this.user.Agent.AgentCode)
            .then(function (json) {
                g.response_data = json.data.auction_data
                g.auction_hash = json.data.auction_hash

                const csv_headers = json.data.auction_data_headers

                g.table_headers = [
                    {
                        text: csv_headers.LeadOrderNumber,
                        value: 'LeadOrderNumber',
                        align: 'start',
                        sortable: true,
                        divider: true,
                        filterable: true
                    },
                    {
                        text: csv_headers.State,
                        value: 'State',
                        align: 'start',
                        sortable: true,
                        divider: true,
                        filterable: true
                    },
                    {
                        text: csv_headers.Quantity,
                        value: 'Quantity',
                        align: 'start',
                        sortable: true,
                        divider: true,
                        filterable: true
                    },
                    {
                        text: csv_headers.Counties,
                        value: 'Counties',
                        align: 'start',
                        sortable: true,
                        divider: true,
                        filterable: true
                    },
                    {
                        text:'',
                        value:'Bid',
                        align:'start',
                        sortable: false,
                        divider:true,
                        filterable: false,
                        width: '7%'
                    }
                ]

                g.data_loading = false

                for (const bidInfo of g.response_data) {
                    bidInfo.BidPlaced = false
                }
            })
            .catch(function(err) {
                console.error('Issue with loading existing lead order auction data',err)
                g.showError("Error loading existing leader order data.")
                g.data_loading = false
            })
        }, 200),
        processBid: async function(bidInfo) {
            console.log('bid Info',bidInfo)
            try {
                const validationData = await QuilityAPI.getExistingLeadOrderValidationData(this.user.Agent.AgentCode,this.auction_hash)

                if(validationData.valid_hash){
                    // open FML A-Lead Order Form

                    this.$set(this,'response_answers',{
                        ID: 'new',
                        FormID: 12,
                        ResponseLocation: null,
                        LeadAssignmentID: null,
                        FormResponseAnswers: {
                            49: {
                                FormQuestionID:49,
                                FormResponseID:'new',
                                AnswerContent: this.user.Agent.AgentName,
                                AnswerDataType: "String"
                            },
                            50: {
                                FormQuestionID:50,
                                FormResponseID:'new',
                                AnswerContent: this.user.Agent.BaseshopOwnerAgentName,
                                AnswerDataType: "String"
                            },
                            51: {
                                FormQuestionID:51,
                                FormResponseID:'new',
                                AnswerContent: bidInfo.Quantity,
                                AnswerDataType: "String"
                            },
                            52: {
                                FormQuestionID:52,
                                FormResponseID:'new',
                                AnswerContent: bidInfo.State,
                                AnswerDataType: "Array"
                            },
                            55: {
                                FormQuestionID:55,
                                FormResponseID:'new',
                                AnswerContent: bidInfo.LeadOrderNumber,
                                AnswerDataType: "String"
                            },
                        }
                    })

                    this.$nextTick(() => {
                        this.showBidForm = true
                        bidInfo.BidPlaced = true
                    })
                }
                else{
                    this.showBidForm = false
                    this.showInfo('There has been an update to the auction datable since this page was loaded. Those updates have been applied to the table. Please try again.')
                    // update the data table with the updated form and hash
                    this.response_data = validationData.auction_data
                    this.table_headers = [
                        {
                            text: validationData.auction_data_headers.LeadOrderNumber,
                            value: 'LeadOrderNumber',
                            align: 'start',
                            sortable: true,
                            divider: true,
                            filterable: true
                        },
                        {
                            text: validationData.auction_data_headers.State,
                            value: 'State',
                            align: 'start',
                            sortable: true,
                            divider: true,
                            filterable: true
                        },
                        {
                            text: validationData.auction_data_headers.Quantity,
                            value: 'Quantity',
                            align: 'start',
                            sortable: true,
                            divider: true,
                            filterable: true
                        },
                        {
                            text: validationData.auction_data_headers.Counties,
                            value: 'Counties',
                            align: 'start',
                            sortable: true,
                            divider: true,
                            filterable: true
                        },
                        {
                            text:'Bid on Order',
                            value:'Bid',
                            align:'start',
                            sortable: false,
                            divider:true,
                            filterable: false,
                            width: '7%'
                        }
                    ]
                    this.auction_hash = validationData.auction_hash
                }
            } catch (error) {
                console.log('Issue with processing the bid request', error)
                this.showError('Issue with processing the bid request')
            }
        },
        updateBid: function(e) {
            this.showBidForm = false
        }
    },
    watch: {

    },
    components: {
        QDataTableHeader,
        FmlQuestionSet,
    },
}
</script>
<style scoped>
</style>