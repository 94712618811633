<template>
    <div class="px-10 py-5 lead-order-detail">
        <v-row>
            <v-col cols="12">
                <h4>Order Details - {{orderId}} </h4>
                <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                <div v-else>
                    <v-simple-table dense class="ml-10 lead-order-table">
                        <thead>
                            <tr>
                                <th v-for="h in the_history_headers" :key="h.value" :class="alignClass(h)">
                                    <span v-html="h.text"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="order in orders">
                            <tr>
                                <td v-for="h in the_history_headers" :key="h.value" :class="alignClass(h)">
                                    <strong>
                                        <span v-if="h.value == 'CreateDate'">
                                            {{ formatDate(order['CreateDate'])}}
                                        </span>
                                        <span v-else-if="h.value == 'OrderDate'">
                                            {{ formatDate(order['OrderDate'])}}
                                        </span>
                                        <span v-else-if="h.value == 'AgentName'">
                                            {{order.AgentName}}
                                            <v-icon right :x-small="true" v-if="order.AgencyOrderInd" style="font-size:8px">fas fa-building</v-icon>
                                        </span>
                                        <span v-else v-html="order[h.value]"></span>
                                    </strong>
                                </td>
                            </tr>
                            <tr v-if="order.LeadOrderLines">
                                <td :colspan="the_history_headers.length">
                                    <v-simple-table dense class="ml-10 lead-order-line-table">
                                        <thead>
                                            <tr>
                                                <th v-for="h in the_order_line_headers" :k="h" :class="alignClass(h)">
                                                    <span v-html="h.text"></span>
                                                </th>
                                            </tr>
                                        </thead>
                        <tbody>
                            <tr v-for="line_item in order.LeadOrderLines">
                                <td v-for="h in the_order_line_headers" :key="h.value" :class="alignClass(h)">
                                    <div v-if="h.value == 'QtyFilledDetails'" :class="alignClass(h) + ' lead-order-line-cell'">
                                        <table dense>
                                            <tr v-for="(qty, key) in line_item[h.value]" :key="key">
                                                <td>{{key}}:</td>
                                                <td>{{qty}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div v-else-if="h.value == 'OrderDate'">
                                        {{ formatDate(line_item['OrderDate'])}}
                                    </div>
                                    <div v-else v-html="line_item[h.value]"></div>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    </td>
                    </tr>
                    </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "QLeadOrderDetails",
    props: ['orderId'],
    data: function() { // data inter
        return {
            loading: false,
            orders: []
        };
    },
    mounted: function() {
        this.reset();
    },
    computed: {
        the_history_headers: function() {
            return [{
                text: 'Create Date',
                value: 'CreateDate',
                align: "left"
            }, {
                text: 'Order Date',
                value: 'OrderDate',
                align: "left"
            }, {
                text: 'Order #',
                value: 'OrderNumber',
                align: "left"
            }, {
                text: 'Agent',
                value: 'AgentName',
                align: "left"
            }, {
                text: 'Baseshop Owner',
                value: 'BaseShopOwnerOptID',
                align: "left"
            }, {
                text: 'Status',
                value: 'OrderStatus',
                align: "left"
            }, {
                text: 'Requested',
                value: 'QtyRequested',
                align: "center"
            }, {
                text: 'Filled',
                value: 'QtyFilled',
                align: "center"
            }]
        },
        the_order_line_headers: function() {
            var r = [{
                text: 'Priority',
                value: 'Priority',
                align: "left"
            }, {
                text: 'Order Date',
                value: 'OrderDate',
                align: "left"
            }, {
                text: 'Status',
                value: 'OrderLineStatus'
            }, {
                text: 'Expiration',
                value: 'ExpirationDate'
            }, {
                text: 'Product',
                value: 'ProductType',
                align: "left"
            }, {
                text: 'State',
                value: 'State',
                align: "left"
            }, {
                text: 'County',
                value: 'County',
                align: "left"
            }, {
                text: 'Filled',
                value: 'QtyFilled',
                align: "center"
            }, {
                value: 'QtyFilledDetails',
                align: "center"
            }]
            return r;
        },
    },
    methods: {
        reset: function() {
            if (this.orderId != null) {
                this.loadOrder()
            }
        },
        loadOrder: function() {
            var g = this
            g.loading = true
            g.orders = []
            var f = {
                attr: "Quantities,LeadOrderLines"
            }
            QuilityAPI.getLeadOrderHistory(g.orderId, f).then(function(json) {
                g.loading = false;
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, "orders", json.data);
            }).catch(function(err) {
                g.loading = false;
                g.showError(err.message);
            });
        },
        alignClass: function(header) {
            if (header.align == "center") {
                return "text-middle text-center"
            }
            if (header.align == "left") {
                return "text-start"
            }
            if (header.align == "right") {
                return "text-end"
            }
        },
    },
    watch: {
        'orderId': function() {
            var g = this
            this.$nextTick(function() {
                g.reset();
            })
        }
    },
    components: {}
}

</script>
<style>
.lead-order-detail .nowrap {
    white-space: nowrap;
}

.lead-order-detail .grey_row {
    background-color: #f7f7f7;
}

.lead-order-detail .lead-order-table {
    font-size: 12px;
}

.lead-order-detail .lead-order-line-table {
    background-color: #f7f7f7;
}

.lead-order-detail .lead-order-line-table table td {
    font-size: .8em;
    width: 10%;
}

</style>
