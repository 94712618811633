<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agent-accounts-admin-submenu></q-agent-accounts-admin-submenu>
        <v-row class="mx-5">
            <v-col cols=12>
                <q-lead-sales-receipt-summary-data-table title="Lead Sales Receipts">
                </q-lead-sales-receipt-summary-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QLeadSalesReceiptSummaryDataTable from '@/components/datatables/LeadOrders/QLeadSalesReceiptSummaryDataTable.vue';
import QAgentAccountsAdminSubmenu from '@/components/navigation/Submenus/QAgentAccountsAdminSubmenu.vue';

export default {
    data() {
        return {
            search: '',
        }
    },
    components: {
        QLeadSalesReceiptSummaryDataTable,
        QAgentAccountsAdminSubmenu
    },
    methods: {

    },

}
</script>