<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="12" sm="6" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7">
                        <p class="q-display-3  pb-0 pt-0 mt-0 mb-0 white--text">{{title}}
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Log
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters" :id="entity + '_datatable'">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table dense v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 nowrap" :footer-props="{itemsPerPageOptions:[100,200,300]}" :loading="loading_data" :options.sync="options"  :server-items-length="total_num">
                <template v-slot:item.CreateDate="{ item }">
                    <span>{{formatDateTime(item.CreateDate)}}</span>
                </template>
                <template v-slot:item.PullStart="{ item }">
                    <td>{{formatDateTime(item.PullStart)}}</td>
                </template>
                <template v-slot:item.PullEnd="{ item }">
                    <td>{{formatDateTime(item.PullEnd)}}</td>
                </template>
                <template v-slot:item.ExportFileLocation="{item}">
                    <td>
                        <v-btn small @click="downloadCSV(item.ExportFileLocation)">Download</v-btn>
                    </td>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "../QDataTableMixin"
import { debounce } from 'vue-debounce'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "QLeadAllocationLogDataTable",
    mixins: [QDataTableMixin],
    props: ['agent'],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead',
            filters: { 'Status': ["Confirmed", "Processing"], 'LastChangeBy': "-All-", 'LeadType': "-All-" },
            options: { sortBy: ['CreateDate'], sortDesc: [true] },
            the_data: [],
            loading_data: false,
            log_available_filters: null,
            total_num : 0,
        }
    },
    mounted: function() {
        this.refreshData();
    },
    computed: {
        filter_keys: function() {
            return ['LastChangeBy', 'LeadType', 'Status']
        },
        all_data: function() {
            return this.the_data;
        },
        the_headers: function() {
            return [{
                text: 'Date',
                value: 'CreateDate',
                align: "left"
            }, {
                text: 'User',
                value: 'LastChangeBy',
                align: "left"
            }, {
                text: 'Lead Type',
                value: 'LeadType',
                align: "center"
            }, {
                text: 'Status',
                value: 'Status',
                align: "center"
            }, {
                text: 'Pulled Since',
                value: 'PullStart',
                align: "left"
            }, {
                text: 'Pulled End Date',
                value: 'PullEnd',
                align: "left"
            }, {
                text: '# Leads',
                value: 'LeadCount',
                align: "center"
            }, {
                text: '# Allocated',
                value: 'AllocatedCount',
                align: "center"
            }, {
                text: 'File',
                value: 'ExportFileLocation',
                align: "left"
            }]
        },
        available_filters: function() {
            return this.log_available_filters;
        },
        total_items: function() {
            return this.total_num;
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            g.loading_data = true;
            var f = { ...this.filters }
            f.Status = this.filters.Status.join(",");
            QuilityAPI.getLeadAllocationLog(f, opt).then(function(json) {
                g.loading_data = false;
                g.the_data = json.data;
                g.total_num = json.meta.total
                if (g.log_available_filters == null) {
                    g.log_available_filters = json.meta.filters
                }
            }).catch(function(e) {
                g.loading_data = false;
                g.showError(e);
            });
        }, 200),
        goToTop: function() {
            this.$vuetify.goTo('#lead_datatable', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
        filterFor(key, v, data) {
            var d = []
            if (v == "-All-") {
                return data
            }
            data.forEach(function(item) {
                if (item[key] == v) {
                    d.push(item)
                }
            })
            return d;
        },
        refresh() {
            this.reset();
        },
        reset() {
            this.the_data = []
            this.refreshData();
            //this.filters = { 'LastChangeBy': "-All-", 'LeadType': "-All-", 'Status': "-All-" };
        },
        downloadCSV: function(url) {
            this.loading_data = true
            var g = this
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            QuilityAPI.downloadAllocationCSV(url)
                .then(function(csv) {
                    g.loading_data = false
                    var blob = new Blob([csv], { type: "text/csv" }),
                        csv_url = window.URL.createObjectURL(blob);
                    a.href = csv_url;
                    a.target = "_blank"
                    a.download = url;
                    a.click();
                    window.URL.revokeObjectURL(csv_url);
                })
        }
    },
    watch: {
        'filters': {
            deep: true,
            handler: function(newV) {
                this.$nextTick(this.reload)
            }
        }
    },
    components: {

    }
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.table-cursor tbody tr:hover {
    cursor: pointer;
}

</style>
