<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <v-row class="mx-5">
            <v-col cols="7" sm="6">
                <h2>Existing Lead Order Auction
                    <v-btn v-if="hasRole(['Exec','SuperAdmin','Staff'])" outlined color="primary" @click="showUpload = !showUpload">Upload</v-btn>
                </h2>
            </v-col>
            <v-spacer v-if="hasRole(['Exec','SuperAdmin','Staff'])"></v-spacer>
            <v-col cols="12" v-if="showUpload && hasRole(['Exec','SuperAdmin','Staff'])">
                <q-file-upload
                    :upload-url="upload_route"
                    :accepted-files="accepted_files"
                    upload-name="document"
                    :max-files="null"
                    :text="upload_text"
                    @complete="update_data_table"
                >
                </q-file-upload>
            </v-col>
        </v-row>
        <v-row justify="space-between" align="stretch" class="mx-5">
            <v-col cols="12">
                <q-existing-lead-order-auction-data-table ref="data_table" title="Existing Lead Orders"></q-existing-lead-order-auction-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QFileUpload from '@/components/utils/QFileUpload.vue'
import QExistingLeadOrderAuctionDataTable from '@/components/datatables/LeadOrders/QExistingLeadOrderAuctionDataTable.vue'
export default {
    name: "ExistingLeadOrderAuction",
    props: [], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            showUpload: false,
            upload_text: "Drag CSV file"
        }
    },
    mounted: function() {

    },
    computed: {
        accepted_files() {
            return [
                'application/csv',
                '.csv',
            ].toString()
        },
        upload_route() {
            return '/api/private/leads/' + this.user.Agent.AgentCode + '/existing_lead_order_auction/'
        }

    },
    methods: {
        update_data_table() {
            this.$refs.data_table.refreshData()
        }
    },
    watch: {

    },
    components: {
        QFileUpload,
        QExistingLeadOrderAuctionDataTable,
    },
}
</script>
<style scoped>
</style>