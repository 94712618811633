<template>
    <v-row wrap>
        <v-col cols="12" md="9">
            <v-row class="internal-toolbar mx-0">
                <v-col cols="12">
                    <p>Recruiting leads are generated on Quility.com, SfgLife.com and other internal recruiting funnels.</p>
                </v-col>
                <v-col cols="12" md="6" v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])">
                    <q-agent-lookup :filters="{'Status' : 'Active,Available,Stalled', 'LeadershipLevel' : 'Agency Owners and Above'}" label="Agent" v-model="AgentCode" placeholder="Start typing to Search for Agent"></q-agent-lookup>
                </v-col>
                <v-col v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])" cols="12" md="6" class="text-right">
                    <v-btn :disabled="disabled_button" color="primary" large @click="startNewOrder()">
                        <v-icon left>fas fa-plus</v-icon>
                        Create A New Lead Order
                    </v-btn>
                </v-col>
                <v-col v-else-if="user.Agent.Status == 'Active' && hasPermission('agent:RecruitingLeadOrders')" cols="4">
                    <v-btn color="primary" large @click="startNewOrder()">
                        <v-icon left>fas fa-plus</v-icon>
                        Create A New Lead Order
                    </v-btn>
                </v-col>
                <v-col v-else cols="12">
                    <v-alert color="orange">
                        Sorry. You cannot create new lead order until your account is "Active" and you have permission from your AO for recruiting orders.
                    </v-alert>
                </v-col>
            </v-row>
        </v-col>
        <v-col v-if="hasLeadershipLevel" cols="12" md="6" class="text-left">
            <v-btn to="/form/recruiting-lead-orders-approval-form" color="primary" large outlined>
                <v-icon left>fas fa-file-alt</v-icon>
                Recruiting Lead Orders Approval Forms
            </v-btn>
        </v-col>
        <v-col cols="12">
            <q-dx-lead-order-data-table :lead-types="['Recruiting']" v-on:editorder="editOrder" v-on:reopenorder="reopenOrder" ref="lead_order_datatable" :agent-code="AgentCode" title="Recruiting Lead Orders" key="recruiting_leadorder_data"></q-dx-lead-order-data-table>
        </v-col>
        <v-dialog ref="new_dx_order_dialog" v-model="showEditLeadOrder" :width="850">
            <v-card class="text-center">
                <q-edit-dx-lead-order-form key="MyDXLeadOrders" ref="lead_order_form" :agent-code="agent_code" :id="current_order_id" v-on:order_saved="orderSaved" v-on:cancel="showEditLeadOrder = false" lead-type="Recruiting" :allow-nation-wide="true"></q-edit-dx-lead-order-form>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import QEditDxLeadOrderForm from './QEditDxLeadOrderForm.vue'
import QDxLeadOrderDataTable from '@/components/datatables/LeadOrders/QDxLeadOrderDataTable.vue'
export default {
    props: [],
    data() {
        return {
            showEditLeadOrder: false,
            AgentCode: null,
            order_AgentCode: null,
            current_order_id: 'new'
        }
    },
    methods: {
        orderSaved: function() {
            this.showEditLeadOrder = false
            this.$refs.lead_order_datatable.refresh();
            this.$refs.lead_order_form.reset();
        },
        editOrder(item) {
            var g = this
            this.order_AgentCode = item.AgentCode;
            this.AgentCode = null;
            this.current_order_id = item.ID
            var order = {
                OrderType: item.OrderType,
                AgentCode: item.agentCode,
                Qty: item.Qty,
                LeadType: item.LeadType,
                LeadOrderLines: [],
                OrderDate: item.OrderDate
            }
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                order.OrderStatus = item.OrderStatus;
            }
            item.lead_order_lines.forEach(function(line) {
                if (line.OrderLineStatus == 'Open') {
                    var l = {
                        "ID": line.ID,
                        "LeadType": line.LeadType,
                        "State": line.State,
                        "Qty": line.QtyRequested,
                        "ProductType": line.ProductType,
                        "County": line.County,
                        "OrderLineStatus": line.OrderLineStatus
                    }
                    order.LeadOrderLines.push(l)
                }
            })
            this.showEditLeadOrder = true
            this.$nextTick(function() {
                g.$refs.lead_order_form.order = order;
                g.$refs.lead_order_form.reset();
            })
        },
        reopenOrder(item) {
            var g = this
            this.order_AgentCode = item.AgentCode;
            this.AgentCode = null;
            this.current_order_id = item.ID
            var order = {
                OrderType: item.OrderType,
                AgentCode: item.agentCode,
                Qty: item.Qty,
                LeadType: item.LeadType,
                LeadOrderLines: [],
                OrderDate: item.OrderDate
            }
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                order.OrderStatus = item.OrderStatus;
            }
            item.lead_order_lines.forEach(function(line) {
                if (line.OrderLineStatus == 'Complete' && item.OrderStatus == 'Complete') {
                    var l = {
                        "ID": line.ID,
                        "LeadType": line.LeadType,
                        "State": line.State,
                        "Qty": line.QtyRequested,
                        "ProductType": line.ProductType,
                        "County": line.County,
                        "OrderLineStatus": 'Open'
                    }
                    order.LeadOrderLines.push(l)
                }
                if (line.OrderLineStatus == 'Canceled' && (item.OrderStatus == 'Canceled' || item.OrderStatus == 'Pending Cancellation')) {
                    var l = {
                        "ID": line.ID,
                        "LeadType": line.LeadType,
                        "State": line.State,
                        "Qty": line.QtyRequested,
                        "ProductType": line.ProductType,
                        "County": line.County,
                        "OrderLineStatus": 'Open'
                    }
                    order.LeadOrderLines.push(l)
                }
            })
            this.showEditLeadOrder = true
            this.$nextTick(function() {
                g.$refs.lead_order_form.order = order;
                g.$refs.lead_order_form.reset();
            })
        },
        startNewOrder() {
            this.current_order_id = "new"
            this.showEditLeadOrder = true
            this.order_AgentCode = null;
            var g = this
            this.$nextTick(function() {
                g.$refs.lead_order_form.reset();
            })
        }
    },
    computed: {
        'disabled_button': function() {
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec']) && this.AgentCode == null) {
                return true;
            }
            return false;
        },
        'agent_code': function() {
            if (this.hasRole(['Staff', 'SuperAdmin', 'Exec'])) {
                return this.order_AgentCode ? this.order_AgentCode : this.AgentCode;
            }
            return this.user.Agent.AgentCode
        },
        hasLeadershipLevel() {
            return [
                "Agency Owner",
                "Agency Director",
                "Regional Agency Director",
                "Managing Vice President",
                "Senior Vice President",
                "Executive Vice President",
                "Associate Partner",
                "Senior Partner",
                "Managing Partner"
            ].includes(this.user.LeadershipLevel)
        },
    },
    watch: {},
    components: {
        QAgentLookup,
        QEditDxLeadOrderForm,
        QDxLeadOrderDataTable
    }
}
</script>

<style>
.internal-toolbar {
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: #ccc thin solid;
    border-radius: 0.2rem;
}
</style>