<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agent-accounts-admin-submenu></q-agent-accounts-admin-submenu>
        <v-row class="mx-5">
            <v-col cols=12>
                <q-agent-account-transaction-data-table ref="ledger" title="Transactions"></q-agent-account-transaction-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
//table data + search
import QAgentAccountTransactionDataTable from '@/components/datatables/AgentAccounts/QAgentAccountTransactionDataTable.vue';
import QAgentAccountsAdminSubmenu from '@/components/navigation/Submenus/QAgentAccountsAdminSubmenu.vue';

export default {
    data() {
        return {
        }
    },
    mounted: function() {
    },
    components: {
        QAgentAccountsAdminSubmenu,
        QAgentAccountTransactionDataTable
    },
    methods: {
        
    },
    watch: {

    },
}
</script>