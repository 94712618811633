<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        base-url="/leads/lead_orders"
        :titlelink="titlelink"
        :sticky="false"
        standard-submenu
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QAgentLeadOrderSubmenu",
    data() {
        return {
            title: "Lead Orders",
            titlelink: []
        }
    },
    computed: {
        buttons: function() {
            const buttons = []
            buttons.push({
                label: "Lead Orders",
                //to: "/leads/dx-lead-orders",
                icon: "fas fa-arrow-alt-circle-right",
                children: []
            })
            if (this.hasPermission("agent:RecruitingLeadOrders")) {
                buttons[0].children.push({
                    label: "Recruiting Lead Orders",
                    to: "/leads/recruiting-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasRole(["Staff", "SuperAdmin"])) {
                buttons[0].children.push({
                    label: "Quility Digital Lead Orders",
                    to: "/leads/quility-digital-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasPermission("agent:LighthouseLeadOrders")) {
                buttons[0].children.push({
                    label: "Lighthouse Lead Orders",
                    to: "/leads/lighthouse-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasPermission("agent:EconomyLeadOrders")) {
                buttons[0].children.push({
					label: "Digital Economy Lead Orders",
                    to: "/leads/economy-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasPermission("agent:Debt Free LifeLeadOrders")) {
                buttons[0].children.push({
                    label: "Debt Free Lead Orders",
                    to: "/leads/dfl-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasPermission("agent:Call InLeadOrders")) {
                buttons[0].children.push({
                    label: "Call In Lead Orders",
                    to: "/leads/call-in-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasRole(['AgencyOwner', 'SuperAdmin', 'Exec', 'Staff'])) {
                buttons[0].children.push({
                    label: "Approve Lead Orders",
                    to: "/leads/approve-digital-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff'])) {
                buttons[0].children.push({
                    label: "Lead Credit Requests",
                    to: "/leads/approve-lead-credit-requests",
                    icon: "fas fa-arrow-alt-circle-right",
                })
            }
            if (this.hasRole(['SuperAdmin', 'Exec', 'Staff'])) {
                buttons.push({
                    label: "Reporting",
                    icon: "fas fa-arrow-alt-circle-right",
                    children: [
                        {
                            label: "The Moloney Report",
                            to: "/leads/the-moloney-report",
                            icon: "fas fa-arrow-alt-circle-right",
                        },{
                            label: "The Moloney Fill Rate",
                            to: "/leads/the-moloney-fillrate-report",
                            icon: "fas fa-arrow-alt-circle-right",
                        },{
                            label: "Grouped By State",
                            to: "/leads/lead-orders-by-state",
                            icon: "fas fa-arrow-alt-circle-right",
                        },{
                            label: "Lead Allocation",
                            to: "/leads/allocate-leads",
                            icon: "fas fa-arrow-alt-circle-right",
                        },{
                            label: "Lead Credit Report",
                            to: "/leads/lead-credit-report",
                            icon: "fas fa-arrow-alt-circle-right",
                        }
                    ]
                })
                buttons.push()
            }
            buttons.push({
                label: "Allocation Report",
                to: "/leads/allocation-report",
                icon: "fas fa-arrow-alt-circle-right",
            })
            return buttons;
        },
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
