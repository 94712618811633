<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1 text-left" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="12" sm="6" id="q-pending-policy-step-3" class="mb-0 pb-0 pl-4">
                        <p class="q-display-3  pb-0 pt-0 mt-0 mb-0 white--text">Approve Downline Lead Orders
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-data-table dense v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 nowrap" :footer-props="{itemsPerPageOptions:[100,200,300]}" :loading="loading_data" :options.sync="options">
                <template v-slot:item.ID="{ item }">
                    <span v-if="item.OrderLineStatus == 'Denied'">
                        Denied
                    </span>
                    <span v-else>
                        <span v-if="hasRole(['SuperAdmin', 'Exec', 'Staff'])">
                            <span v-if="!item.HomeOfficeApprovalInd">
                                <v-btn fab :disabled="loading_data" small color="primary" @click="approveLineItem(item, true)">
                                    <v-icon>fas fa-check</v-icon>
                                </v-btn>&nbsp;
                                <v-btn fab :disabled="loading_data" small color="red" @click="approveLineItem(item, false)">
                                    <v-icon>fas fa-times</v-icon>
                                </v-btn>
                            </span>
                        </span>
                        <span v-else>
                            <span v-if="!item.AgencyOwnerApprovalInd">
                                <v-btn fab :disabled="loading_data" small color="primary" @click="approveLineItem(item, true)">
                                    <v-icon>fas fa-check</v-icon>
                                </v-btn>&nbsp;
                                <v-btn fab :disabled="loading_data" small color="red" @click="approveLineItem(item, false)">
                                    <v-icon>fas fa-times</v-icon>
                                </v-btn>
                            </span>
                        </span>
                    </span>
                </template>
                <template v-slot:item.LeadOrder.OrderDate="{ item }">
                    {{formatDate(item.LeadOrder.OrderDate)}}
                </template>
                <template v-slot:item.HomeOfficeApprovalInd="{ item }">
                    <v-icon v-if="item.HomeOfficeApprovalInd === true" color="green" small>fas fa-check</v-icon>
                </template>
                <template v-slot:item.AgencyOwnerApprovalInd="{ item }">
                    <v-icon v-if="item.AgencyOwnerApprovalInd === true" color="green" small>fas fa-check</v-icon>
                </template>
                <template v-slot:item.SwitchboardConfig="{ item }">
                    <v-tooltip top v-if="item.SwitchboardConfig != null">
                        <template v-slot:activator=" { on, attrs }">
                            <v-icon color="green" small>fas fa-check</v-icon>
                        </template>
                        <span>Switchboard Connected</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "../QDataTableMixin"
import { debounce } from 'vue-debounce'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "QApproveDigitalLeadOrderDataTable",
    mixins: [QDataTableMixin],
    props: ['agent'],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead',
            filters: { 'Status': ["Confirmed", "Processing"], 'LastChangeBy': "-All-", 'LeadType': "-All-" },
            options: { sortBy: ['CreateDate'], sortDesc: [true] },
            the_data: [],
            loading_data: false,
            log_available_filters: null,
            approving: false
        }
    },
    mounted: function() {
        this.refreshData();
    },
    computed: {
        filter_keys: function() {
            return ['LastChangeBy', 'LeadType', 'Status']
        },
        all_data: function() {
            return this.the_data;
        },
        the_headers: function() {
            return [{
                text: 'Approve/Deny',
                value: 'ID',
                align: "center"
            }, {
                text: 'Date',
                value: 'LeadOrder.OrderDate',
                align: "left"
            }, {
                text: 'Agent',
                value: 'LeadOrder.AgentName',
                align: "left"
            }, {
                text: 'Switchboard',
                value: 'SwitchboardConfig',
                align: "center"
            },{
                text: 'Order #',
                value: 'LeadOrder.OrderNumber',
                align: "center"
            }, {
                text: 'State',
                value: 'State',
                align: "center"
            }, {
                text: '# Requested',
                value: 'QtyRequested',
                align: "center"
            }, {
                text: 'Lead Type',
                value: 'LeadOrder.LeadType',
                align: "left"
            }, {
                text: 'Order Type',
                value: 'LeadOrder.OrderType',
                align: "left"
            }, {
                text: 'HO Approved',
                value: 'HomeOfficeApprovalInd',
                align: "center"
            }, {
                text: 'AO Approved',
                value: 'AgencyOwnerApprovalInd',
                align: "center"
            }]
        },
        available_filters: function() {
            return this.log_available_filters;
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            g.loading_data = true;
            var f = {
                AgencyOwnerApprovalInd: false,
                attr: 'LeadOrder,Quantities,SwitchboardConfig'
            }
            f.Status = this.filters.Status.join(",");
            QuilityAPI.getDownlineLeadOrderLines(f, opt).then(function(json) {
                g.loading_data = false;
                g.the_data = json.data;
            }).catch(function(e) {
                g.loading_data = false;
                g.showError(e);
            });
        }, 200),
        goToTop: function() {
            this.$vuetify.goTo('#lead_datatable', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
        filterFor(key, v, data) {
            var d = []
            if (v == "-All-") {
                return data
            }
            data.forEach(function(item) {
                if (item[key] == v) {
                    d.push(item)
                }
            })
            return d;
        },
        refresh() {
            this.reset();
        },
        reset() {
            this.the_data = []
            this.refreshData();
            //this.filters = { 'LastChangeBy': "-All-", 'LeadType': "-All-", 'Status': "-All-" };
        },
        reSyncLineItem(line) {
            var g = this
            this.the_data.forEach(function(item, ix) {
                if (item.ID == line.ID) {
                    g.$set(g.the_data, ix, { ...g.the_data[ix], ...line })
                }
            })
        },
        approveLineItem(item, approved) {
            var g = this
            item.Approved = approved
            g.loading_data = true;
            QuilityAPI.approveLeadOrderLine(item).then(function(json) {
                g.loading_data = false;
                g.$nextTick(function() {
                    g.reSyncLineItem(json.data)
                })
            }).catch(function(e) {
                g.loading_data = false;
                g.showError(e);
            });
        }
    },
    watch: {
        'filters': {
            deep: true,
            handler: function(newV) {
                this.$nextTick(this.reload)
            }
        }
    },
    components: {

    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.table-cursor tbody tr:hover {
    cursor: pointer;
}
</style>